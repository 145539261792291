import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div class="container">
          <object type="image/svg+xml" data={logo}>Taafir Logo</object>
        </div>
        <p>
          We are upgrading our system, soon you will see a new look of our web page.
        </p>
      </header>
    </div>
  );
}

export default App;
